import React from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import Body from './body';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

class Cat extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      cats: []
    };

  }

  async componentDidMount() {

    try {

      ReactGA.pageview('/' + this.props.match.params.cat);
      ReactPixel.pageView();

      axios.get('https://cms.hellodude.it/api/categories').then(cats => {
        this.setState( { cats: cats.data.data });
      });

    }

    catch(err) {

      alert(err);

    }

  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.match.params.cat.charAt(0).toUpperCase() + this.props.match.params.cat.slice(1)} — DUDE</title>
          <meta name="description" content="Siamo specializzati in web design, social media, video making e content creation." />
          <meta property="og:image" content="https://www.hellodude.it/cover.jpg" />
        </Helmet>
        <Body cat={ this.props.match.params.cat } />
      </div>
    );
  }
}

export default Cat;

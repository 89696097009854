import React from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Body from './body';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const qs = require('qs');

class Single extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      projects: [],
      single: null,
    };

  }

  async componentDidMount() {

    try {

      ReactGA.pageview('/' + this.props.match.params.cat + '/' + this.props.match.params.single);
      ReactPixel.pageView();

      const query = qs.stringify({
        populate: ['categories', 'Content', 'Content.Background', 'Info', 'Mockup', 'Cover'],
        pagination: { limit: -1 }
      }, { encodeValuesOnly: true });

      axios.get(`https://cms.hellodude.it/api/projects?${ query }`).then(projects => {
        this.setState( { projects: projects.data.data });
        var single = this.object_search(this.props.match.params.single);
        if (single !== false && !single.attributes['DisableClick']) { this.setState({ single: single }); }
      });


    }

    catch(err) {

      alert(err);

    }

  }

  object_search(TitleToSearch) {

    var result = false;

    this.state.projects.filter(item => {
      if (this.sanitize_title(item.attributes.Title) === this.sanitize_title(TitleToSearch)) { result = item; }
    });

    return result;

  }

  sanitize_title(t) {

    return t.toLowerCase().split(' ').join('-');

  }

  unsanitize_title(t) {

    return t.split('-').join(' ');

  }

  render() {

    return (
      <>
        { this.state.single ? (
          <div>
            <Helmet>
              <title>{ this.state.single.attributes.Title.charAt(0).toUpperCase() + this.state.single.attributes.Title.slice(1) } — DUDE</title>
              <meta name="description" content={ this.state.single.attributes.Subtitle } />
              <meta property="og:description" content={ this.state.single.attributes.Subtitle } />
              <meta property="og:image" content={ 'https://cms.hellodude.it' + this.state.single.attributes['Cover']['url'] } />
            </Helmet>
            <Body cat={ this.props.match.params.cat } single={ this.state.single.attributes.Title } />
          </div>
        ) : (
          <div></div>
        ) }
      </>
    )

  }

}

export default Single;

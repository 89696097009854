import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Home from './home';
import Cat from './cat';
import Single from './single';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

class App extends React.Component {

  constructor(props) {

    super(props);
  }

  componentWillMount() {

    ReactGA.initialize('G-1QNKX1CLEC');
    ReactPixel.init('2923484104430889');

  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={ (props) => (<Home { ...props } />) } />
          <Route exact path="/:cat" render={ (props) => (<Cat { ...props } />) } />
          <Route exact path="/:cat/:single" render={ (props) => (<Single { ...props } />) } />
        </Switch>
      </BrowserRouter>
    )
  }

}

export default App;

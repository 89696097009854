import React from 'react';
import { Helmet } from 'react-helmet';
import Body from './body';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

class Home extends React.Component {

  componentDidMount() {

    ReactGA.pageview('/');
    ReactPixel.pageView();

  }

  render() {
    return (
      <div>
        <Helmet>

          <title>Studio Dude — Agenzia di innovazione culturale</title>
          <meta name="description" content="Siamo specializzati in web design, social media, video making e content creation." />
          <meta property="og:image" content="https://www.hellodude.it/cover.jpg" />

          <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
          <link rel="manifest" href="/favicon/site.webmanifest" />
          <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#0000ff" />
          <meta name="msapplication-TileColor" content="#f4f2ff" />
          <meta name="theme-color" content="#f4f2ff" />

        </Helmet>
        <Body />
      </div>
    );
  }
}

export default Home;

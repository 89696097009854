import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { TimelineLite, TweenMax, TimelineMax } from "gsap/TweenMax";
import SplitText from "gsap/SplitText";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { LineChart, XAxis, YAxis, CartesianGrid, Legend, Line, ResponsiveContainer } from 'recharts';

const qs = require('qs');

class Body extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      home: { attributes: {} },
      cats: [],
      projects: [],
      team: [],
      single: { attributes: { Title: '', Subtitle: '', Cover: { data: { attributes: { 'url': '' } } }, Mockup: { data: { attributes: { 'url': '' } } }, Info: [], Content: [] } },
      projectsItem_zIndex: 0,
      timeline0: null,
      timeline1: null,
      timeline_ticker: null,
      current: 0,
      projects_selected: [{ id: 0, attributes: {} }],
      cat_selected: 0,
      height: window.innerHeight,
      inverted: false
    };

  }

  componentDidMount() {

    const promise1 = axios.get('https://cms.hellodude.it/api/pages/1').then(home => {
      this.setState( { home: home.data.data });
      return;
    });

    const promise2 = axios.get('https://cms.hellodude.it/api/categories').then(cats => {
      this.setState( { cats: cats.data.data });
      return;
    });

    const query = qs.stringify({
      populate: ['categories', 'Content', 'Content.Background', 'Info', 'Mockup', 'Cover'],
      pagination: { limit: -1 }
    }, { encodeValuesOnly: true });

    const promise3 = axios.get(`https://cms.hellodude.it/api/projects?${ query }`).then(projects => {
      console.log(projects);
      this.setState({ projects: projects.data.data });
      this.setState({ projectsItem_zIndex: this.state.projects.length+1 });
      return;
    });

    const promise4 = axios.get('https://cms.hellodude.it/api/teams?populate=*').then(team => {
      console.log(team.data.data);
      this.setState( { team: team.data.data });
      return;
    });

    Promise.all([promise1, promise2, promise3, promise4]).then((values) => {

      document.addEventListener("mousemove", (e) => {

        TweenMax.set(this.cursor, { css: { left: e.clientX, top: e.clientY } });

        TweenMax.to(this['img_projects'], 0.30, {
          left: (e.clientX-120) - (this.projects.clientHeight/4),
          top: ((e.clientY+3) - (this.projects.clientHeight/4)) + this.projects.scrollTop
        });

        TweenMax.to(this.cursor, 0.15, { css: { transform: 'rotate(0deg)' } });

      });

      this.single_content.addEventListener('scroll', (event) => {

        var value1 = (50 - (this.single_content.scrollTop * 0.50));
        var value1_2 = 1;

        if (value1 < 0) {
          value1 = 0; value1_2 = 1 - ((this.single_content.scrollTop -  window.innerHeight - 140) * 0.50);
        }

        var value2 = 1 - (this.single_content.scrollTop / 8);
        if (value2 < 0) { value2 = 0; }

        TweenMax.to([this.single_infos, this.single_h3], 0.30, { opacity: value2 });
        TweenMax.to(this.single_cover, 0.30, { left: value1 + '%' , top: value1 + '%', opacity: value1_2 });

        TweenMax.to(this.cursor, 0.15, { transform: 'rotate(0deg)' });

      }, true);

      var intro_split = new SplitText(this.intro, {type:"words, lines"});
      var right_header_split = new SplitText(this.right_header, {type:"words,lines"});
      var right_footer_split = new SplitText(this.right_footer, {type:"words,lines"});
      var socials_split = new SplitText(this.socials, {type:"lines"});
      var invert_split = new SplitText(this.invert, {type:"lines"});
      var back_split = new SplitText(this.back_span, {type:"lines"});

      var start = new TimelineLite();

      start
      .to(document.body, 0.30, { opacity: 1 })
      .to([this.line_right, this.line_left], 0.30, { height: '100%' })
      .to(this.logo, 0.15, { opacity: 1, marginTop: 0 })
      .to(this.line_top, 0.30, { width: '100%' })

      .set(intro_split.lines, { overflow: 'hidden' })
      .set(this.intro, { opacity: 1 })
      .staggerFromTo(intro_split.words, 0.30, { y: '100%' }, { y: '0%', onComplete: () => {

        intro_split.revert();

        for (var i = 0; i < document.getElementById("intro").getElementsByTagName('a').length; i++) {

          document.getElementById("intro").getElementsByTagName('a')[i].addEventListener('mouseenter', (e) => {

            var hover = new TimelineLite();

            hover
            .to(e.currentTarget, 0.30, {backgroundPositionX: e.currentTarget.offsetWidth + 'px'})
            .to(e.currentTarget, 0, {backgroundPositionX: '0px', backgroundSize: '0% 2px'})
            .to(e.currentTarget, 0.30, { backgroundSize: '100% 2px'});

          });

          document.getElementById("intro").getElementsByTagName('a')[i].addEventListener('click', (e) => {

            e.preventDefault();
            this.cats_click(socials_split, right_footer_split, back_split, e.currentTarget.className);

          });

        }

      }})

      .to(this.line_bottom, 0.30, { width: '100%' })

      .set(this.socials, { opacity: 1 })
      .staggerFromTo(socials_split.lines, 0.15, { y: '100%' }, { y: '0%' })

      .set(this.invert, { opacity: 1 })
      .staggerFromTo(invert_split.lines, 0.15, { marginTop: '100%' }, { marginTop: '0%' })

      .set(right_header_split.lines, { overflow: 'hidden' })
      .set(this.right_header, { opacity: 1 })
      .staggerFromTo(right_header_split.words, 0.15, { y: '100%' }, { y: '0%' })

      .set(right_footer_split.lines, { overflow: 'hidden' })
      .set(this.right_footer, { opacity: 1 })
      .staggerFromTo(right_footer_split.words, 0.15, { y: '100%' }, { y: '0%' });

      if (this.props.cat || this.props.single) {

        start.timeScale(999);

      }

      if (this.props.cat) {

        this.cats_click(socials_split, right_footer_split, back_split, this.props.cat, this.props.single);

      }

      window.addEventListener('resize', (e) => {

        var timeline = new TimelineLite();

        if (this.state.current > 0) {
          timeline.set(this.cats_triangle, {
            width: this['cats_' + this.state.cat_selected].offsetWidth - 30,
            left: this['cats_' + this.state.cat_selected].offsetLeft + 15
          });
        }

        this.setState({ height: window.innerHeight });

        if (this.state.current === 2) {

          if (this.state.timeline_ticker) {
            this.state.timeline_ticker.kill();
          }

          new TweenMax.set(this.ticker, { left: '0px' });

          var time = (this.state.projects_selected.length*4);
          var timeline_ticker = new TimelineMax({repeat: -1, yoyo: true});

          if (window.innerWidth > 1023) {
            timeline_ticker.to(this.ticker, time, {left: '-' + (this.ticker.offsetWidth - (window.innerWidth - 300)) + 'px'});
          } else {
            timeline_ticker.to(this.ticker, time, {left: '-' + (this.ticker.offsetWidth - (window.innerWidth - 30)) + 'px'});
          }

          this.setState({ timeline_ticker: timeline_ticker });

        }

      });

    });

  }

  cats_click(socials_split, right_footer_split, back_split, class_name, single = '') {

    if (this.cats_search(class_name) !== true && class_name !== 'team') {
      window.history.pushState('', '', '/');
      document.title = 'DUDE — Agenzia di innovazione culturale';
      ReactGA.pageview('/');
      ReactPixel.pageView();
      return;
    }

    var timeline = new TimelineLite();

    timeline
    .to([this.right_footer, this.right_header, this.socials, this.intro, this.invert], 0.30, { opacity: 0 })
    .fromTo([this.home, this.right], 0.30, { width: '100%' }, { width: 120 })
    .fromTo(this.logo, 0.30, { width: 120, height: 130 }, { width: 59, height: 64 }, '-=0.30')
    .fromTo(this.projects, 0.30, { width: '0%', left: '100%' }, { width: '100%', left: '0px' }, '-=0.30')

    .to(this.right_footer, 0.1, { rotation: -90, left: -75, bottom: 96 })
    .to(this.socials, 0.1, { rotation: -90, bottom: 82, left: 1 })

    .staggerTo([socials_split.lines, right_footer_split.words, back_split.lines], 0.15, { y: '100%' })
    .to([this.socials, this.right_footer, this.back], 0.15, { opacity: 1, pointerEvents: 'auto' })
    .staggerTo([socials_split.lines, right_footer_split.words, back_split.lines], 0.15, { y: '0%' })
    .to(this.back_span_abs, 0.15, { opacity: 1 });

    if (single) {
      timeline.timeScale(999);
    }

    this.setState({ timeline0: timeline });

    this.cats_change(class_name, 1, single);

  }

  cats_hover(c) {

    TweenMax.to(this.cats_triangle, 0.15, {
      width: this['cats_' + c].offsetWidth - 30,
      left: this['cats_' + c].offsetLeft + 15
    });

  }

  cats_unhover() {

    TweenMax.to(this.cats_triangle, 0.15, {
      width: this['cats_' + this.state.cat_selected].offsetWidth - 30,
      left: this['cats_' + this.state.cat_selected].offsetLeft + 15
    });

  }

  cats_change(class_name, type = 0, single = '') {

    var projects_selected = [];
    var timeline = new TimelineLite();

    this.setState({ cat_selected: class_name }, () => {

      if (!single) {
        window.history.pushState('', '', '/' + class_name);
        document.title = (class_name.charAt(0).toUpperCase() + class_name.slice(1)) + ' — DUDE';
        ReactGA.pageview('/' + class_name);
        ReactPixel.pageView();
      }

    });

    TweenMax.to(this.cats_triangle, 0.15, {
      width: this['cats_' + class_name].offsetWidth - 30,
      left: this['cats_' + class_name].offsetLeft + 15
    });

    this.setState(
      { projects_selected: projects_selected },
      () => {

        var i = 0

        if (class_name !== 'team') {

          for (i = 0; i < this.state.projects.length; i++) {

            this.state.projects[i].attributes.categories.data.map((item, ii) => {

              if (item.attributes['Title'] === class_name) {

                projects_selected[projects_selected.length] = this.state.projects[i];

              }

            });

          }

        } else {

          for (i = 0; i < this.state.team.length; i++) {

            projects_selected[projects_selected.length] = this.state.team[i];

          }

        }

        this.setState(
          { projects_selected: projects_selected },
          () => {

            this.content_projects.scrollTo(0, 0);

            var project_split = [];

            if (type === 1) { timeline.delay(1.15); }

            for (i = 0; i < this.state.projects_selected.length; i++) {

              project_split[i] = new SplitText(this['title_item_projects_' + i], {type:"words,lines"});

              timeline
              .set(project_split[i].words, { y: '100%' })
              .set(this['item_projects_' + i], { opacity: 1 })
              .staggerTo(project_split[i].words, 0.15, { y: '0%' }, 0.15)
              .set(this['item_projects_' + i], { pointerEvents: 'auto'});

            }

            timeline.eventCallback("onComplete", () => {
              for (i = 0; i < this.state.projects_selected.length; i++) {
                if (project_split[i]) {
                  project_split[i].revert();
                }
              }
            });

            this.setState({ current: 1 });

            if (single) {
              this.projectsItem_click(this.props.single, '', single);
            }

          }
        );

      }
    );

  }

  projectsItem_hover(id, i) {

    this.setState({ projectsItem_zIndex: this.state.projectsItem_zIndex+1 });

    new TimelineLite()
      .set(this['img_projects'], { opacity: 0.85 })
      .set(this['img_projects_' + id], { zIndex: this.state.projectsItem_zIndex, width: '0%' })
      .to([this['img_projects_' + id], this['h2_item_projects_' + i]], 0.30, { width: '100%' });

  }

  projectsItem_unhover(id) {


    for (var i = 0; i < this.state.projects_selected.length; i++) {
      TweenMax.set(this['h2_item_projects_' + i], { width: '0%' });
    }

  }

  projects_unhover() {

      TweenMax.to(this['img_projects'], 0.30, { width: 0 });

      setTimeout(() => {

        TweenMax.set(this['img_projects'], { width: '100%', opacity: 0 });

        for (var i = 0; i < this.state.projects.length; i++) {
          TweenMax.set(this['img_projects_' + this.state.projects[i]['id']], { width: '0%' });
        }

      }, 400);

  }

  projectsItem_click(id, blank, single = '', disableClick = false) {

    if (!blank && !disableClick) {

      var timeline = new TimelineLite();

      timeline
      .fromTo(this.single, 0.1, { width: '0%' }, { width: '100%' })
      .fromTo(this.single, 0.30, { left: '100%' }, { left: '0px' });

      if (single) {
        timeline.timeScale(999);
      }

      this.setState({ timeline1: timeline });

      this.setState({ current: 2 });

      var select = this.object_search(id);

      this.setState({ single: select }, () => {

        this.single_h3.innerHTML = this.state.single.attributes['Title'] + (this.state.single.attributes.Subtitle ? ' — ' + this.state.single.attributes.Subtitle : '');
        var title = new SplitText(this.single_h3, {type:"chars,words"});

        var mockup;

        if (select.attributes['Video']) {

          mockup = this.single_video.getElementsByTagName('iframe')[0];

          this.single_video.getElementsByTagName('iframe')[0].addEventListener("mouseenter", (e) => {

            TweenMax.set(this.cursor, { css: { opacity: 0 }});

          });

          this.single_video.getElementsByTagName('iframe')[0].addEventListener("mouseout", (e) => {

            TweenMax.set(this.cursor, { css: { opacity: 1 }});

          });

        } else {

          mockup = new Image();

        }

        mockup.onload = () => {

          window.history.pushState('', '', '/' + this.state.cat_selected + '/' + this.sanitize_title(select.attributes['Title']));
          document.title = (select.attributes['Title'].charAt(0).toUpperCase() + select.attributes['Title'].slice(1)) + ' — DUDE';
          ReactGA.pageview('/' + this.state.cat_selected + '/' + this.sanitize_title(select.attributes['Title']));
          ReactPixel.pageView();

          var timeline_click = new TimelineLite();

          timeline_click
          .set(title.words, { overflow: 'hidden' }, '+=0.5')
          .set(this.single_h3, { opacity: 1 })
          .staggerFromTo(title.chars, 0.30, { y: '100%' }, { y: '0%' })

          .to(this.single_infos, 0.60, { opacity: 1 });

          for(var i = 0; i < this.state.single.attributes.Info.length; i++) {
            timeline_click.to(this['infos_desc_' + i], 0.15, { top: 15 }, '-=0.10');
          }

          timeline_click
          .delay(0.15)
          .to(this.single_cover, 0.60, { top: '50%', left: '50%' });

          TweenMax.to(this.cursor, 0.15, { css: { transform: 'rotate(-135deg)' }, delay: 1.5 });

        }

        if (!select.attributes['Video']) {
          mockup.src = 'https://cms.hellodude.it' + (select.attributes['Mockup'].data ? select.attributes['Mockup'].data.attributes['url'] : '');
        }

        if (this.state.timeline_ticker) {
          this.state.timeline_ticker.kill();
        }

        new TweenMax.set(this.ticker, { left: '0px' });

        var time = (this.state.projects_selected.length*4);
        var timeline_ticker = new TimelineMax({repeat: -1, yoyo: true});

        if (window.innerWidth > 1023) {
          timeline_ticker.to(this.ticker, time, {left: '-' + (this.ticker.offsetWidth - (window.innerWidth - 300)) + 'px'});
        } else {
          timeline_ticker.to(this.ticker, time, {left: '-' + (this.ticker.offsetWidth - (window.innerWidth - 30)) + 'px'});
        }

        this.setState({ timeline_ticker: timeline_ticker });

      });

    }

  }

  go_back(recursive = 0) {

    if (this.state.current === 2) {

      window.history.pushState('', '', '/' + this.state.cat_selected);
      document.title = (this.state.cat_selected.charAt(0).toUpperCase() + this.state.cat_selected.slice(1)) + ' — DUDE';
      ReactGA.pageview('/' + this.state.cat_selected);
      ReactPixel.pageView();

      this.state.timeline1.timeScale(1).reverse();

      setTimeout(() => {

        this.single_content.scrollTo(0, 0);

        new TimelineLite()
        .delay(0.30)
        .to(this.single_h3, 0.1, { opacity: 0.0 })
        .to(this.single_infos, 0.1, { opacity: 0.0 })
        .to(this.single_cover, 0.1, { top: '200%', left: '200%' });

        var infos = new TimelineLite().delay(0.30);
        for(var i = 0; i < this.state.single.attributes.Info.length; i++) {
          infos.to(this['infos_desc_' + i], 0.1, { top: 44 }, '-=0.1');
        }

        if (this.state.single.attributes['Video']) {
          var single = this.state.single;
          single.attributes['Video'] = '';
          this.setState({ single: single });
        }

      }, 300);

      if (recursive === 1) {

        window.history.pushState('', '', '/');
        document.title = 'DUDE — Agenzia di innovazione culturale';
        ReactGA.pageview('/');
        ReactPixel.pageView();

        if (this.state.timeline0) {

          this.state.timeline0.timeScale(1).reverse();

        }

      }

    } else if (this.state.current === 1) {

      window.history.pushState('', '', '/');
      document.title = 'DUDE — Agenzia di innovazione culturale';
      ReactGA.pageview('/');
      ReactPixel.pageView();

      if (this.state.timeline0) {

        this.state.timeline0.timeScale(1).reverse();

      }

    }

    this.setState({ current: this.state.current-1 });

  }

  cats_search(TitleToSearch) {

    var result = false;

    this.state.cats.filter(item => {
      if (this.sanitize_title(item.attributes['Title']) === this.sanitize_title(TitleToSearch)) { result = true; }
    });

    return result;

  }

  object_search(TitleToSearch) {

    var result = false;

    this.state.projects_selected.filter(item => {
      if (this.sanitize_title(item.attributes['Title']) === this.sanitize_title(TitleToSearch)) { result = item; }
    });

    return result;

  }

  compare(a, b) {

    const dateA = a.Data;
    const dateB = b.Data;

    let comparison = 0;
    if (dateA > dateB) { comparison = 1; } else if (dateA < dateB) { comparison = -1; }

    return comparison;

  }

  sanitize_title(t) {

    return t.toLowerCase().split(' ').join('-');

  }

  image_effect(i, item, type) {

    if (type === 0) {

      new TimelineLite().delay(0.90).to(this[item + i], 0.60, { width: '100%', opacity: 1 });

    } else {

      TweenMax.to(this[item + i], 0.60, { opacity: 1 });

    }

  }

  image_load(i, item, type, url) {

    if (url) {

      var image = new Image();

      image.onload = () => {

        this.image_effect(i, item, type);

      }

      image.src = 'https://cms.hellodude.it' +  url.attributes['url'];

    }

  }

  invert_color() {

    this.inverted_color.innerHTML = this.website.innerHTML;

    new TimelineLite().set(this.inverted_color, { height: '100%' });

    if (document.getElementById("website").classList.contains('invert')) {
      this.state.inverted = false;
      document.getElementById("website").classList.remove("invert");
      document.getElementById("inverted_color").classList.add("invert");
    } else {
      this.state.inverted = true;
      document.getElementById("website").classList.add("invert");
      document.getElementById("inverted_color").classList.remove("invert");
    }

    new TimelineLite().to(this.inverted_color, 0.60, { height: '0%' });

    setTimeout(() => { this.inverted_color.innerHTML = ''; }, 600);


  }

  singleItem_click(id, blank) {

    var timeline = new TimelineLite();

    timeline
    .fromTo(this.single_related, 0.1, { width: '0%' }, { width: '100%' })
    .fromTo(this.single_related, 0.30, { left: '100%' }, { left: '0px' });

    setTimeout(() => {

      this.go_back();

      setTimeout(() => {

        this.projectsItem_click(id, blank);

        setTimeout(() => {
          timeline.timeScale(999).reverse();
        }, 400);

      }, 800);

    }, 400);

  }

  render() {

    return (

      <div>

        <div id="website" ref={ website => this.website = website }>

          <div className="cursor" ref={ cursor => this.cursor = cursor }></div>

          <div id="start">

          <div id="home" ref={ home => this.home = home }>

            <div className="line right" ref={ line_right => this.line_right = line_right }></div>

            <div className="header">
              <div className="relative">

                <div className="logo" onClick={() => this.go_back(1)} ref={ logo => this.logo = logo }></div>

              </div>
            </div>

            <div className="intro" id="intro">
              <div className="relative">

                <div className="line top" ref={ line_top => this.line_top = line_top }></div>

                <div className="back" ref={ back => this.back = back } onClick={ () => this.go_back() }>
                  <div className="relative">

                    <div className="span" ref={ back => this.back_span = back }>↑</div>
                    <div className="span_abs" ref={ back => this.back_span_abs = back }>
                      <div className="arrow">
                        <div>↑</div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="table">
                  <div className="table_cell">

                    <div className="text" ref={ intro => this.intro = intro }>
                      {ReactHtmlParser(this.state.home.attributes.Content)}
                    </div>

                  </div>
                </div>

                <div className="line bottom" ref={ line_bottom => this.line_bottom = line_bottom }></div>

              </div>
            </div>

            <div className="footer">

              <div className="table">
                <div className="table_cell bottom">

                  <div className="socials" ref={ socials => this.socials = socials }>
                    <p><span role="img" aria-label="jsx-a11y/aria-proptypes">🌱</span><a rel="noopener noreferrer" target="_blank" href="https://fb.com/studioduderoma" className="social">FB</a> — <span role="img" aria-label="jsx-a11y/aria-proptypes">🌳</span><a rel="noopener noreferrer" target="_blank" href="https://instagr.am/_studio_dude" className="social">IG</a></p>
                  </div>

                  <div className="invert_color" ref={ invert => this.invert = invert } onClick={() => this.invert_color()}>
                    <div className="round"></div>
                    <div className="text black">dark mode</div>
                    <div className="text white">light mode</div>
                  </div>

                </div>
              </div>

              <div className="line_bottom"></div>

            </div>

          </div>

          <div id="right" ref={ right => this.right = right }>

            <div className="line left" ref={ line_left => this.line_left = line_left }></div>

            <div className="relative">

              <div className="top">
                <div className="header" ref={ right_header => this.right_header = right_header }>
                  <a target="_blank" rel="noopener noreferrer" ref={ right_map_url => this.right_map_url = right_map_url } href="https://goo.gl/maps/kv3G2kg9ymSsq8y27">via della botticella, 27</a><br />
                  00153 — Roma
                </div>
                <div className="line_bottom"></div>
              </div>

              <div className="bottom">
                <div className="footer" ref={ right_footer => this.right_footer = right_footer }>
                  <a href="mailto:ciao@studiodude.it">ciao@studiodude.it</a><br />
                  <a href="tel:+390639727369">+39 0639 727369</a>
                </div>
                <div className="line_bottom"></div>
              </div>

            </div>

          </div>

          </div>

          <div id="projects" ref={ projects => this.projects = projects }>
            <div className="relative">

              <div className="header">

                <div className="relative">

                  <div className="triangle" ref={ triangle => this.cats_triangle = triangle }></div>

                  <div className="cats">

                    <div className="cat" id={'cats_home'} onClick={() => this.go_back()}>←</div>

                    <div
                      className={ this.state.cat_selected === 'team' ? "cat_selected" : "cat" }
                      id={'cats_studio'}
                      ref={ cats => this['cats_team'] = cats }
                      onClick={() => this.cats_change('team')}
                      onMouseEnter={ () => this.cats_hover('team') }
                      onMouseLeave={ () => this.cats_unhover() }
                    >studio</div>

                    {this.state.cats.map((item, i) => {
                      return(
                        <div
                          className={ this.state.cat_selected === this.state.cats[i].attributes['Title'] ? "cat_selected" : "cat" }
                          id={'cats_' + this.state.cats[i].attributes['Title']}
                          key={'cats_' + i}
                          ref={ cats => this['cats_' + this.state.cats[i].attributes['Title']] = cats }
                          onClick={() => this.cats_change(this.state.cats[i].attributes['Title'])}
                          onMouseEnter={ () => this.cats_hover(this.state.cats[i].attributes['Title']) }
                          onMouseLeave={ () => this.cats_unhover() }
                        >
                          {this.state.cats[i].attributes['Title']}
                        </div>
                      )
                    })}

                  </div>

                </div>

                <div className="shadow"></div>

              </div>

              <div className="content" ref={ content_projects => this.content_projects = content_projects }>

                <div className="list" onMouseLeave={ () => this.projects_unhover() }>
                {this.state.projects_selected.sort((a, b)=> a.attributes['Title'] > b.attributes['Title'] ? 1 : -1).map((item, i) => {
                  return(

                    <div
                      className="item"
                      ref={ item_projects => this['item_projects_' + i] = item_projects }
                      key={'item_projects_' + i}
                      onMouseEnter={ () => this.projectsItem_hover(this.state.cat_selected + '_' + this.state.projects_selected[i]['id'], i) }
                      onMouseLeave={ () => this.projectsItem_unhover(this.state.cat_selected + '_' + this.state.projects_selected[i]['id'], i) }
                      onClick={ () => this.projectsItem_click(this.state.projects_selected[i].attributes['Title'], this.state.projects_selected[i].attributes['URL'], '', this.state.projects_selected[i].attributes['DisableClick']) }
                    >
                      <LazyLoadComponent afterLoad={ () => this.image_effect(i, 'image_item_projects_', 0) }>
                        <div ref={ image_item_projects => this['image_item_projects_' + i] = image_item_projects } style={{ backgroundImage: 'url(https://cms.hellodude.it' + (this.state.projects_selected[i].attributes['Cover'] ? this.state.projects_selected[i].attributes['Cover'].data.attributes['url'] : '') + ')' }} className="img"></div>
                      </LazyLoadComponent>
                      <div className="relative">
                        <div className="title" ref={ title_item_projects => this['title_item_projects_' + i] = title_item_projects }>
                          { this.state.projects_selected[i].attributes['Title'] }
                        </div>
                        <div className="h2" ref={ h2_item_projects => this['h2_item_projects_' + i] = h2_item_projects }>
                          <h2>
                            { this.state.projects_selected[i].attributes['Title'] }
                          </h2>
                        </div>
                      </div>
                    </div>

                  )
                })}
                </div>

                <div className="imgs" ref={ img_projects => this['img_projects'] = img_projects }>
                  <div className="relative">
                  {this.state.projects.map((item, i) => {
                    return(

                      <div
                        ref={ img_projects => this['img_projects_' + this.state.cat_selected + '_' + this.state.projects[i]['id']] = img_projects }
                        key={'img_projects_' + this.state.cat_selected + '_' + this.state.projects[i]['id']}
                        style={{
                          backgroundImage: 'url(https://cms.hellodude.it' + (this.state.projects[i].attributes['Cover'] ? this.state.projects[i].attributes['Cover'].data.attributes['url'] : '') + ')',
                          zIndex: i+1
                        }}
                        className="img"
                      >
                        <a href={'/' + this.state.projects[i].attributes['categories'].data[0].attributes['Title'] + '/' + this.sanitize_title(this.state.projects[i].attributes['Title'])}> </a>
                      </div>

                    )
                  })}
                  {this.state.team.sort((a,b)=> a.attributes['Title'] > b.attributes['Title'] ? 1 : -1).map((item, i) => {
                    return(
                      <>
                        { this.state.inverted ? (
                          <div
                            ref={ img_projects => this['img_projects_team_' + this.state.team[i]['id']] = img_projects }
                            key={'img_projects_team_' + this.state.team[i]['id']}
                            style={{
                              backgroundImage: 'url(https://cms.hellodude.it' + (this.state.team[i].attributes['CoverBlack'] && this.state.team[i].attributes['CoverBlack'].data ? this.state.team[i].attributes['CoverBlack'].data.attributes['url'] : '') + ')',
                              zIndex: i+1
                            }}
                            className="img"
                          >
                          </div>
                        ) : (
                          <div
                            ref={ img_projects => this['img_projects_team_' + this.state.team[i]['id']] = img_projects }
                            key={'img_projects_team_' + this.state.team[i]['id']}
                            style={{
                              backgroundImage: 'url(https://cms.hellodude.it' + (this.state.team[i].attributes['Cover'] ? this.state.team[i].attributes['Cover'].data.attributes['url'] : '') + ')',
                              zIndex: i+1
                            }}
                            className="img"
                          >
                          </div>
                        ) }
                      </>

                    )
                  })}
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div id="single" ref={ single => this.single = single }>

            <div className="first">

              <div ref={ single_cover => this.single_cover = single_cover } className="cover">
                <div className="relative">

                  <div ref={ single_video => this.single_video = single_video } className="video">
                    <div className="table">
                      <div className="table_cell">
                        <div className="video_content">
                          { ReactHtmlParser(this.state.single.attributes['Video']) }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="img" style={{ backgroundImage: 'url(https://cms.hellodude.it' + (this.state.single.attributes['Mockup'].data ? this.state.single.attributes['Mockup'].data.attributes['url'] : '') + ')' }}></div>

                </div>
              </div>

            </div>

            <div className="content" ref={ single_content => this.single_content = single_content }>

              <div className="slides">

                <div className="slide transparent">
                  <div className="padding">

                    <div className="back" onClick={() => this.go_back()}>←</div>

                    <h3 ref={ single_h3 => this.single_h3 = single_h3 }> </h3>

                    <div className="infos" ref={ single_infos => this.single_infos = single_infos }>

                    {this.state.single.attributes.Info.map((item, i) => {
                      let name = item.Name;
                      if (name) { name = item.Name.toUpperCase(); }
                      return(

                        <div className="item" key={'infos_' + i}>
                          <div className="name">{ item.Name }</div>
                          <div className="desc" ref={ infos_desc => this['infos_desc_' + i] = infos_desc }>
                            { ReactHtmlParser(item.Desc) }
                          </div>
                          <div className="line"></div>
                        </div>

                      )
                    })}

                    </div>
                  </div>

                </div>

                <div className="slide transparent fixed"></div>

                {this.state.single.attributes.Content.map((item, i) => {
                  if (item.Chart) {
                    return (
                      <div
                        style={{
                          backgroundImage: 'url(https://cms.hellodude.it' + (item['Background'].data ? item['Background'].data.attributes['url'] : '') + ')',
                          backgroundColor: item['Color'],
                          backgroundSize: item['Type'],
                          minHeight: this.state.height
                        }}
                        className={ 'slide' + (item['Padding'] ? ' padding' : '') }
                        key={ 'slide_' + i }
                      >

                        <div className="chart table abs white">
                          <div className="text">

                            <LazyLoadComponent>
                              <ResponsiveContainer width="99%" aspect={2}>
                                <LineChart style={{ fontFamily: 'SuisseIntl-SemiBold', fontSize: 10 }} data={item.Chart.Data}>
                                  <Line type="monotone" dataKey="Follower" stroke="#0000FF" />
                                  <XAxis dataKey="name" stroke="#000" />
                                  <YAxis stroke="#000" />
                                  <CartesianGrid stroke="#000" strokeDasharray="5 5" />
                                  <Legend verticalAlign="top" height={35}/>
                                </LineChart>
                              </ResponsiveContainer>
                            </LazyLoadComponent>

                          </div>
                        </div>

                        <div className="chart table abs black">
                          <div className="text">

                            <LazyLoadComponent>
                              <ResponsiveContainer width="99%" aspect={2}>
                                <LineChart style={{ fontFamily: 'SuisseIntl-SemiBold', fontSize: 10 }} data={item.Chart.Data}>
                                  <Line type="monotone" dataKey="Follower" stroke="#0000FF" />
                                  <XAxis dataKey="name" stroke="#FFF" />
                                  <YAxis stroke="#FFF" />
                                  <CartesianGrid stroke="#FFF" strokeDasharray="5 5" />
                                  <Legend verticalAlign="top" height={35}/>
                                </LineChart>
                              </ResponsiveContainer>
                            </LazyLoadComponent>

                          </div>
                        </div>

                      </div>
                    )
                  } else {
                    return (
                      <div
                        style={{ minHeight: this.state.height }}
                        className={ 'slide' + (item['Padding'] ? ' padding' : '') }
                        ref={ el => this['item_single_' + i] = el }
                        key={ 'slide_' + i }
                      >

                        <LazyLoadComponent afterLoad={ () => this.image_load(i, 'single_image_', 1, item['Background'].data) }>

                          <div
                            style={{
                              backgroundImage: 'url(https://cms.hellodude.it' + (item['Background'].data ? item['Background'].data.attributes['url'] : '') + ')',
                              backgroundColor: item['Color'],
                              backgroundSize: item['Type']
                            }}
                            className={'image' + (item['Padding'] ? ' padding' : '')}
                            ref={ el => this['single_image_' + i] = el }
                          ></div>

                        </LazyLoadComponent>

                        <div className="table abs">

                          <div className="text">{ ReactHtmlParser(item['Text']) }</div>

                        </div>

                        <div className="table">
                          <div className="table_cell">

                            <div className="text">{ ReactHtmlParser(item['Text']) }</div>

                          </div>
                        </div>

                      </div>
                    )
                  }
                })}

              </div>

              <div className="ticker-wrap">
                <div ref={ ticker => this.ticker = ticker } id="ticker" className="ticker">
                {this.state.projects_selected.map((item, i) => {
                  if (this.state.current === 2 && (this.state.single['Title'] !== this.state.projects_selected[i].attributes['Title'])) {
                  return(

                    <div
                      className="ticker__item"
                      key={'item_ticker_single_' + i}
                      onClick={ () => this.singleItem_click(this.state.projects_selected[i].attributes['Title'], this.state.projects_selected[i].attributes['URL']) }
                    >
                      <div className="relative">
                        <div className="title">
                          {this.state.projects_selected[i].attributes['Title']}
                        </div>
                        <div className="h2">
                          <h2>
                            {this.state.projects_selected[i].attributes['Title']}
                          </h2>
                        </div>
                      </div>
                    </div>

                  )
                  }
                })}
                </div>
              </div>

              <div className="footer">

                <div className="text opacity">
                  Vuoi discutere con noi del tuo brand o della tua azienda? Siamo qui, contattaci.<br /><br />
                  <a href="tel:0639727369">0639 727369</a> — <a href="mailto:ciao@studiodude.it">ciao@studiodude.it</a>
                </div>

                <div className="fixed">

                  <div className="text">
                    Vuoi discutere con noi del tuo brand o della tua azienda? Siamo qui, contattaci.<br /><br />
                    <a href="tel:0639727369">0639 727369</a> — <a href="mailto:ciao@studiodude.it">ciao@studiodude.it</a>
                  </div>

                </div>
              </div>

            </div>

          </div>

          <div id="single_related" ref={ single_related => this.single_related = single_related }>

          </div>

        </div>

        <div id="inverted_color" ref={ inverted_color => this.inverted_color = inverted_color }></div>

      </div>

    );
  }
}

export default withRouter(Body);
